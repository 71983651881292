<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button label="Benutzer hinzufügen" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
          <Button label="Benutzer löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length" />
        </template>

        <template #end>
          <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import"
            class="mr-2 d-inline-block" />
          <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
        </template>
      </Toolbar>

      <DataTable ref="dt" :value="system_user" v-model:selection="selectedIOs" dataKey="_id" :loading="loading"
        :paginator="true" :rows="50" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Benutzern" responsiveLayout="scroll">
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div class="
              table-header
              d-flex flex-column flex-md-row jc-md-between
            ">
            <h5 class="mb-2 m-md-0 as-md-center">Benutzer verwalten</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="suchen..." />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="username" header="Username" :sortable="true"></Column>
        <Column field="fullname" header="Name" :sortable="true"></Column>
        <Column field="created" header="Erstellt" :sortable="true">
        </Column>
        <Column field="lastseen" header="Letzter Login" :sortable="true"></Column>
        <Column field="value.type" header="IO-Art" :sortable="true"> </Column>
        <Column field="value.unit" header="Einheit" :sortable="true"> </Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
              @click="editProduct(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
              @click="confirmDeleteProduct(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Benutzer bearbeiten" :modal="true"
      class="p-fluid" @hide="dialogClosed">
      <!-- <div class="p-formgrid p-grid">
        <div class="p-field p-col">
          <div class="p-field p-text-center">
            <span :class="ioDialogData.metadata.icon + ' mif-5x'"></span>
          </div>
        </div>
        <div class="p-field p-col">
          <div
            class="p-field p-text-center"
            v-if="ioDialogData.value.value !== undefined"
          >
            <h2>
              {{ ioDialogData.value.value
              }}<small>{{ " " + ioDialogData.value.unit }}</small>
            </h2>
          </div>
          <div class="p-field p-text-center" v-else>
            <h2>???<small>???</small></h2>
          </div>
        </div>
      </div> -->

      <div class="field">
        <label for="username">Username</label>
        <InputText id="username" v-model.trim="ioDialogData.username" required="true" autofocus
          :class="{ 'p-invalid': submitted && !ioDialogData.username }" />
        <small class="p-error" v-if="submitted && !ioDialogData.username">Name <b>muss</b> ausgefüllt werden!</small>
      </div>
      <div class="field">
        <label for="password">Passwort</label>
        <InputText id="password" type="password" v-model.trim="ioDialogData.password" required="true" autofocus
          :class="{ 'p-invalid': changePwd && ioDialogData.password !== retypePwd }" @click="passwordChange" />
        <small class="p-error" v-if="submitted && !ioDialogData.password">Passwort <b>muss</b> ausgefüllt
          werden!</small>
      </div>
      <div v-if="changePwd" class="field">
        <label for="password2">Passwort wiederholen</label>
        <InputText id="password2" type="password" v-model.trim="retypePwd" required="true" autofocus
          :class="{ 'p-invalid': ioDialogData.password !== retypePwd }" @click="passwordChange" />
        <small class="p-error" v-if="submitted && !ioDialogData.password2">Passwort <b>muss</b> ausgefüllt
          werden!</small>
      </div>
      <div class="field">
        <label for="fullname">Anzeige-Name</label>
        <Textarea id="fullname" v-model="ioDialogData.fullname" required="true" rows="3" cols="20" />
      </div>

      <div class="formgrid grid">
        <div class="field col">
          <label>Benutzergruppe</label>
          <Dropdown id="usergroup" v-model="ioDialogData.role" :options="usersGroups" optionLabel="name"
            optionValue="role">
            <template #option="slotProps">
              <div>
                <span class="text-small">{{ slotProps.option.name }}</span>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="field-checkbox col">
          <Checkbox id="active" name="active" v-model="ioDialogData.active" :binary="true" />
          <label for="active">Benutzer aktivieren</label>
        </div>
      </div>

      <div class="formgrid grid">
        <div class="field col-8">
          <label for="emailAddress">Email-Adresse</label>
          <InputText id="emailAddress" v-model.trim="ioDialogData.notifications.emailAddress" required="false" autofocus />
        </div>
        <div class="field-checkbox col-4">
          <Checkbox id="active" name="active" v-model="ioDialogData.notifications.emailEnable" :binary="true" />
          <label for="active">Mail-Meldungen</label>
        </div>
      </div>

      <div class="formgrid grid">
        <div class="field col-8">
          <label for="emailAddress">Pushover User Key</label>
          <InputText id="emailAddress" v-model.trim="ioDialogData.notifications.pushoverUserKey" required="false" autofocus />
        </div>
        <div class="field-checkbox col-4">
          <Checkbox id="active" name="active" v-model="ioDialogData.notifications.pushoverEnable" :binary="true" />
          <label for="active">Pushover-Meldungen</label>
        </div>
      </div>

      <div class="p-inputgroup" v-if="isMaster">
          <span class="p-inputgroup-addon">Erlaubte Anlagen</span>
          <MultiSelect v-model="ioDialogData.config.departments" display="chip" :options="getDepartments" optionLabel="key" placeholder="Anlagen" class="w-full md:w-20rem">
          <template #option="slotProps">
              <div class="flex align-items-center">
                  <div>[{{ slotProps.option.key }}] {{ slotProps.option.label }}</div>
              </div>
            </template>
          </MultiSelect>
      </div>

      <div class="formgrid grid">
        <div class="field col">
          <label>ID</label>
          <InputText id="_id" v-model="ioDialogData._id" readonly />
        </div>
      </div>

      <template #footer>
        <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
        <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Bestätigung" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData">Soll der Benutzer wirklich aus der Datenbank gelöscht werden: <br />
          <b>{{ ioDialogData.username }}</b> ?</span>
      </div>
      <template #footer>
        <Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
        <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Bestätigung" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData">Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span>
      </div>
      <template #footer>
        <Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
        <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mongoResponseToast } from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";
import { mapGetters } from "vuex";

export default {
  name: "UserTable",
  data() {
    return {
      loading: false,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      ioDialogData: {},
      system_user: null,
      retypePwd: null,
      changePwd: false,
      oldPassword: null,
      newPassword: null,
      selectedProducts: null,
      selectedIOs: null,
      filters: {},
      submitted: false,
      intval_Value: null,
    };
  },
  productService: null,
  created() {
    // this.productService = new ProductService();
    this.initFilters();
    this.$store.dispatch('users/loadUserGroups');
  },
  mounted() {
    // this.productService.getProducts().then((data) => (this.products = data));
    this.loading = true;
    this.getParameter();
  },
  beforeUnmount() {
    this.ioDialogData = {};
    this.changePwd = null;
    this.retypePwd = null;
    this.changePwd = null;
    this.oldPassword = null;
    this.newPassword = null;
    this.selectedProducts = null;
    this.submitted = false;
    this.filters = {};
  },
  computed: {
    ...mapGetters({
      usersGroups: 'users/getUserGroups',
      isLoggedIn: 'auth/isLoggedIn',
      loginStatus: 'auth/loginStatus',
      getUser: 'auth/getUser',
      viewMenu: 'viewMenu',
      getDepartments: 'types/getDepartments',
      getAlarmPrior: 'types/getAlarmPrior',
      isMaster: 'auth/isMaster',
    }),
    // ...mapGetters('users', ['getUserGroups']),
  },
  methods: {
    resetDialogData() {
      this.ioDialogData = {
        username: null,
        password: null,
        fullname: null,
        active: true,
        role: null,
        config: {
          theme: "dark",
          lang: "de-AT",
          departments: []
        },
        notifications: {
          pushoverEnable: false,
          pushoverUserKey: null,
          emailEnable: false,
          emailAddress: null
        },
        group: {}
      };
      this.oldPassword = null;
      this.newPassword = null;
      this.changePwd = false;
      this.retypePwd = null;
    },
    getUserList() {
      this.loading = true;
      SocketioService.getUserList((err, response) => {
        if (err) {
          this.$toast.add({
            severity: "error",
            summary: `Fehler bei Server Anfrage`,
            detail: `Benutzerliste konnte nicht geladen werden`,
            life: 3000,
          });
        } else {
          this.system_user = response;
          this.loading = false;
        }
      });
    },
    // getUserGroupList() {
    //   SocketioService.getUserGroupList((err, response) => {
    //     if (err) {
    //       this.$toast.add({
    //         severity: "error",
    //         summary: `Fehler bei Server Anfrage`,
    //         detail: `Gruppenliste konnte nicht geladen werden`,
    //         life: 3000,
    //       });
    //     } else {
    //       this.system_usergroup = response;
    //     }
    //   });
    // },
    getParameter() {
      this.getUserList();
      // this.getUserGroupList();
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.resetDialogData();
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
    },
    dialogClosed() {
      this.submitted = false;
      this.resetDialogData();
      // clearInterval(this.intval_Value);
      this.getUserList();
    },
    passwordChange(event) {
      if (event !== undefined) {
        this.changePwd = true;
        this.oldPassword = this.ioDialogData.password;
        event.target.value = null;
      }
    },
    saveDialog() {
      this.submitted = true;
      let tempData = null;

      if (this.ioDialogData.username.trim()) {
        const _index = this.findIndexById(this.ioDialogData._id);
        if (_index >= 0) {
          this.system_user[_index] = this.ioDialogData;
          if (this.changePwd && this.ioDialogData.password === this.retypePwd) {
            this.system_user[_index].password = this.oldPassword;
            this.system_user[_index].newPassword = this.ioDialogData.password;
          }
          console.log(this.system_user[_index]);
          tempData = this.system_user[_index];
        } else {
          console.log(this.ioDialogData);
          tempData = { ...this.ioDialogData };
          if (this.changePwd && this.ioDialogData.password === this.retypePwd) {
            tempData.newPassword = this.ioDialogData.password;
          }
        }
        SocketioService.setUserList(tempData, (err, response) => {
            if (err) {
              this.$toast.add({
                severity: "error",
                summary: `Fehler bei Server Anfrage`,
                detail: `Benutzerliste konnte nicht geladen werden`,
                life: 3000,
              });
            } else {
              mongoResponseToast(response, this.$root);
              // this.system_user = response;
              this.loading = false; 
            }
          });
        this.productDialog = false;
        this.resetDialogData();
      } else {
        this.$toast.add({
              severity: "danger",
              summary: "Fehlerhafte Benutzerkonfiguration",
              detail: "Felder wie Passwort kontrollieren",
              life: 3000,
            });
      }
    },
    editProduct(ioData) {
      // this.ioDialogData = { ...ioData };
      console.log('editproduct')
      this.getUserList();
      console.log(ioData);
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.productDialog = true;
      // this.intval_Value = setInterval(this.updateOpenValue, 1000);
    },
    confirmDeleteProduct(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      // this.products = this.products.filter((val) => val.id !== this.product.id);
      let _index = this.findIndexById(this.ioDialogData._id);
      if (_index >= 0) {
        this.system_user[_index] = this.ioDialogData;
        SocketioService.delUser(this.ioDialogData, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getUserList();
          }
        });
        // SocketioService.removeUser(this.system_user[_index]);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Updated",
        //   life: 3000,
        // });
      } else {
        // this.product.id = this.createId();
        // this.product.code = this.createId();
        // this.product.image = "product-placeholder.svg";
        // this.products.push(this.product);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Created",
        //   life: 3000,
        // });
      }
      this.deleteProductDialog = false;
      this.resetDialogData();
      // this.$toast.add({
      //   severity: "success",
      //   summary: "Successful",
      //   detail: "Product Deleted",
      //   life: 3000,
      // });
    },
    updateOpenValue() {
      // let _index = this.findIndexById(this.ioDialogData._id);
      // if (_index >= 0) {
      //   // SocketioService.getStatusIO((response) => {
      //   //   this.ioDialogData.value.value = response[_index].value.value;
      //   // });

      // }
      SocketioService.getSingleValue(this.ioDialogData._id, (response) => {
        this.ioDialogData.value.value = response.value.value;
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.system_user.length; i++) {
        if (this.system_user[i]._id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      SocketioService.removeValue(this.selectedIOs);
      this.deleteProductsDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>